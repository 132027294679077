import React from "react";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import "./AboutUs.css";
import sharvariProfile from "../assets/sharvari.jpeg";
import prathmeshProfile from "../assets/prathmesh.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Navbar from "./Navbar";
import Footer from "./Footer";

const AboutUs = () => {
	return (
		<div className="aboutUsDiv">
			<Navbar renderBurger={false} />
			<div class="container aboutUsContainer">
				<div class="cardContainer">
<div class="col-sm-6 col-md-4 personalCard">
					<div class="team-item">
						<img src={prathmeshProfile} class="team-img" alt="pic" />
						<div class="team-info">
							<div class="nameAndIcon">
								<h3>Prathmesh Kale</h3>
								<a
									href="http://linkedin.com/in/prathmesh-kale-ba1350b3"
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon class="linkedInClass" icon={faLinkedin} />
								</a>
							</div>
							<p>CEO</p>
						</div>
						<p>
							Co-Founder, CEO
						</p>
					</div>
				</div>
				<div class="col-sm-6 col-md-4 personalCard">
					<div class="team-item">
						<img src={sharvariProfile} class="team-img" alt="pic" />

						<div class="team-info">
							<div class="nameAndIcon">
								<h3>Sharvari Kale</h3>
								<a
									href="https://www.linkedin.com/in/sharvari-kale-058692168"
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon class="linkedInClass" icon={faLinkedin} />
								</a>
							</div>
							<p>CTO</p>
						</div>

						<p>
							Co-Founder, CTO
						</p>
					</div>
				</div>
        </div>
        
			</div>
			<Footer />
			</div>
	);
};

export default AboutUs;
