import React from "react";
import appleStoreImage from "../assets/availableOnAppleStore.png";
import playStoreImage from "../assets/availableOnPlayStore.png";
import Navbar from "./Navbar";
const Home = () => {
  return (
    <div>
      <Navbar renderBurger />
      <div className="home-banner-container">
        <div className="home-text-section">
          <h1 className="primary-heading">
            Investments Tracking At Ease With Rupeeease™
          </h1>
          <p className="primary-text">
            Categorize your assets and liabilities, keep track of them, know
            your net worth and achieve your future goals.
          </p>
          <div className="store-images-div">
            <img
              className="store-images"
              src={playStoreImage}
              alt="on Play store"
            />
            <img
              className="store-images-apple"
              src={appleStoreImage}
              alt="on apple store"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
