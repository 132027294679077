import React from "react";

const Contact = () => {
  return (
    <div className="contact-page-wrapper">
      <h1 className="primary-heading">Have Question In Mind?</h1>
      <h1 className="primary-sub-heading">Please send an email at help@rupeeease.com</h1>
    </div>
  );
};

export default Contact;
