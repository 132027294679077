/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Logo from "../assets/appName.png";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";
import "./NavbarStyles.css";

const Navbar = ({ renderBurger }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const menuOptions = [
    {
      text: "About Us",
      icon: <InfoIcon />,
      path: "/AboutUs",
    },
  ];
  return (
    <nav>
      <Link className="navbarTitle" to="/">
        <img src={Logo} alt="" />
      </Link>
      {renderBurger ? (
        <>
          <div className="navbar-links-container">
            <Link to="/AboutUs">About Us</Link>
          </div>
          <div className="navbar-menu-container">
            <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
          </div>
          <Drawer
            open={openMenu}
            onClose={() => setOpenMenu(false)}
            anchor="right"
          >
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={() => setOpenMenu(false)}
              onKeyDown={() => setOpenMenu(false)}
            >
              <List>
                {menuOptions.map((item) => (
                  <ListItem
                    key={item.text}
                    disablePadding
                    component={Link}
                    to={item.path}
                  >
                    <ListItemButton>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <Divider />
            </Box>
          </Drawer>
        </>
      ) : (
        <></>
      )}
    </nav>
  );
};

export default Navbar;
