import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

import './PrivacyPolicyStyles.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <Navbar />
      <div className="privacy-banner-container">
        <div className="privacy-text-section">
          <h1 className="privacy-primary-heading">
            Privacy Policy
          </h1>
          <p className="privacy-primary-text">
            <p className="privacy-primary-text"> Updated On: August 24th, 2023</p>
            <p className="privacy-primary-text">
              Rupeeease ("us", "we", "our" or "application") informs you of our policies regarding the collection, use, and disclosure of personal data when you use our application and the choices you have associated with that data.
              We collect several types of information for various purposes to improve our application.
            </p>

            <p className="privacy-primary-text">
             While using our application, we may ask you to provide us with certain personally identifiable information ("Personal Data") that may include, 
             but is not limited to, your name, email address. We may collect this information when you register for an account. We also collect and securely store your bank account information
             and your financial assets. The application also requests notification and may require camera permissions to notify user about tracked resources deadlines. 
             We use this information to improve our application, communicate with you, and track your financial information safely.
            </p>

            
            <p className="privacy-primary-text">
              We may disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency). 
              The security of your Personal Data is important to us. We implement reasonable security measures to protect the confidentiality, integrity, and availability of your Personal Data. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure and we cannot guarantee its absolute security.
            </p>

            <p className="privacy-primary-text">
              You have certain rights regarding your Personal Data, including the right to access, correct, delete, or restrict its processing. You may also have the right to object to or request the transfer of your Personal Data. If you wish to exercise any of these rights, please contact us using the information provided below.
            </p>

            <p className="privacy-primary-text">
              Our application is not intended for anyone under the age of 13 ("Children"). We do not knowingly collect Personal Data from Children. If you are a parent or guardian and you are aware that your Children have provided us with Personal Data, please contact us immediately. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.
            </p>

            <p className="privacy-primary-text">
              Your information, including Personal Data, may be transferred to and maintained on computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. 
              Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
            </p>

            <p className="privacy-primary-text">
              We may update our Privacy Policy periodically. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Updated on:" date at the top of this Privacy Policy.
            </p>

            <p className="privacy-primary-text">
                Contact Us If you have any questions or concerns about this Privacy Policy, please contact us:
            </p>

            <p className="privacy-primary-text">
                Email: help@rupeeease.com 
            </p>

            <p className="privacy-primary-text">
                Address: Nashik, Maharashtra, India
            </p>

            <p className="privacy-primary-text">
                By using Rupeeease, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and disclosure of your personal data as described herein.
            </p>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
