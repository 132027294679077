import React from "react";
import InvestMentForm from "../assets/InvestmentForm.png";
import Dashboard from "../assets/dashboard.png";
import Goal from "../assets/goal.png";
import PayAndTrack from "../assets/payAndTrack.png"

const Work = () => {
  const workInfoData = [
    {
      image: InvestMentForm,
      title: "Fill investment form",
      text: "Provide your investment details and let us do the work for you.",
    },
    {
      image: Goal,
      title: "Set your goals",
      text: "This will help you to plan your financial targets easily",
    },
    {
      image: PayAndTrack,
      title: "Pay and Track",
      text: "You can pay your expenses through us and we will record it for you.",
    },
    {
      image: Dashboard,
      title: "Dashboard",
      text: "Dashboard helps you to understand where you stand and provides the graphical representations",
    }
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Workflow</p>
        <h1 className="primary-heading">Follow these simple steps</h1>
        <p className="primary-text">
          
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
