import React from "react";

const About = () => {
  return (
    <div className="about-section-container">
      <div className="about-section-text-container">
        <h1 className="primary-heading">Summarizes your investments</h1>
        <p className="primary-text">
          Graphs to show your investments patterns <br />
          Analysis of yor investmensts to predict the future profitable
          investments
        </p>
      </div>
    </div>
  );
};

export default About;
